<template lang="pug">
	#app-container.h-100(:class='(me && me.id) ? getMenuType : {}')
		top-nav
		main.d-flex.h-100.position-relative
			sidebar.d-none.d-lg-block(v-if='me && me.id')
			.main-router-wrap
				router-view
		modal-mobile-menu
		modal-wizard(v-if='showWizard')
		modal-status(v-if='showStatus')
		modal-status-success(v-if='showStatusSuccess')
		modal-wizard-success
</template>

<script>
import Sidebar from '@/containers/Sidebar';
import MobileMenu from '@/components/Modals/MobileMenu';
import TopNav from '@/containers/TopNav';
import { mapGetters } from 'vuex';
import Wizard from '@/components/Modals/Wizard';
import ModalStatus from '@/components/Modals/Status';
import ModalStatusSuccess from '@/components/Modals/StatusSuccess';
import WizardSuccess from '@/components/Modals/ModalWizardSuccess';

export default {
    components: {
        TopNav,
        Sidebar,
        'modal-mobile-menu': MobileMenu,
        'modal-wizard': Wizard,
        'modal-status': ModalStatus,
        'modal-status-success': ModalStatusSuccess,
        'modal-wizard-success': WizardSuccess,
    },
    data: () => ({
        isProjectLoaded: false,
    }),
    created() {
        if (this.$route.query && this.$route.query.authRequestId) this.$router.replace({ query: null });
    },
    beforeMount() {
        document.body.classList.add('background-index');
    },
    destroyed() {
        document.body.classList.remove('background-index');
    },
    computed: {
        ...mapGetters({
            getMenuType: 'getMenuType',
            project: 'project/opened',
            accountListActiveRemoved: 'finance/accountsActiveRemoved',
            isPendingProject: 'project/GETTING_PROJECT',
        }),

        showWizard() {
            this.me;
            this.accountListActiveRemoved;
            this.project;
            this.me.accountLoaded;
            this.isProjectLoaded;
            if (this.project) this.project.isReady();
            if (this.me) this.me.hasFilledAccount();

            const ms = this.project?.moderationRequestLast?.status;
            if (['BLOCKED'].includes(ms)) {
                return false;
            }

            return (
                this.me?.accountLoaded &&
                this.accountListActiveRemoved &&
                this.isProjectLoaded &&
                this.project &&
                (!this.project.isReady() || !this.me.hasFilledAccount() || !this.me.hasFilledPaymentAccounts())
            );
        },

        showStatus() {
            //hack
            this.project;
            this.me;
            this.me.accountLoaded;
            const ms = this.project?.moderationRequestLast?.status;

            return (
                !this.showWizard && this.me?.accountLoaded && this.project && !['APPROVED', 'UNBLOCKED'].includes(ms)
            );
        },
        showStatusSuccess() {
            //hack
            this.project;
            this.me;
            this.me.accountLoaded;

            if (this.project?.isNeuro()) {
                return !this.showStatus && this.me?.accountLoaded;
            }

            const ms = this.project?.moderationRequestLast?.status;
            return !this.showStatus && this.me?.accountLoaded && ms && ['APPROVED'].includes(ms);
        },
    },
    watch: {
        isPendingProject(v, a) {
            if (!v) this.isProjectLoaded = true;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/css/sass/_mixins.scss';

#app-container {
    @include respond-below(md) {
        height: 100%;
    }

    main {
        background: var(--background-color);

        @include respond-below(md) {
            height: 100%;
        }
    }

    .main-router-wrap {
        position: relative;
        padding: 24px 0 24px 20px;
        flex-grow: 1;
        width: 75%;

        @include respond-below(lg) {
            width: percentage(855px / 1140px);
        }

        @media (max-width: 1150px) {
            width: percentage(805px / 1090px);
        }

        @media (max-width: 1100px) {
            width: percentage(755px / 1040px);
        }

        @media (max-width: 1050px) {
            width: percentage(705px / 990px);
        }

        @include respond-below(md) {
            width: 100%;
            padding: 0;
        }
    }
}
</style>
